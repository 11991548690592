:root {
  font-family: Inter;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-color-primary: #3f906d;
  --bg-color-secondary: #bdbdbd;
  --Neutral-Background: #fbfbfc;

  --error-main: #d32f2f;
}
body {
  margin: 0;
  font-family: Inter;
  background-color: var(--Neutral-Background);
}
*, *::before, *::after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Default box-sizing for all elements */
}
